import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Line,
  // Area,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  // CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';
// import Box from '@mui/material/Box';
import { ImDiamonds } from 'react-icons/im';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const ComposedCharts = ({ childLayout, chrt_id, apidata, item }) => {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {
      const [barData] = payload;
      return (
        <>
          <Card sx={{ maxWidth: 300 }}>
            <CardContent>
              {Object.keys(barData.payload).map((key) => (
                <Typography key={key} noWra fontWeight="bold">
                  {key}:{' '}
                  {key === 'Frequency Compliance'
                    ? barData.payload[key] + '%'
                    : barData.payload[key]}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </>
      );
    }

    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.any,
    value: PropTypes.any
  };

  const CustomizedLabel = ({ x, y, value }) => (
    <>
      <text x={x} y={y} dy={15} fill="black" style={{ fontSize: '12px', fontWeight: 'bold' }}>
        {`${value}%`}
      </text>
    </>
  );

  CustomizedLabel.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    value: PropTypes.any
  };

  const CustomizedLabelNotpercent = ({ x, y, value }) => (
    <>
      <text x={x} y={y} dy={15} fill="black" style={{ fontSize: '12px', fontWeight: 'bold' }}>
        {`${value}`}
      </text>
    </>
  );

  CustomizedLabelNotpercent.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    value: PropTypes.any
  };

  const colors = [
    '#FF5733', // Red-Orange
    '#33FF57', // Green
    '#3357FF', // Blue
    '#FF33A1', // Pink
    '#FFC300', // Yellow
    '#DAF7A6', // Light Green
    '#900C3F', // Dark Red
    '#581845', // Purple
    '#C70039', // Red
    '#FFC0CB', // Light Pink
    '#008080', // Teal
    '#FFD700' // Gold
  ];

  // console.log('bar_line_dot...', q);

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={730}
          height={250}
          barGap={20}
          data={chart_data?.chartTableData ? q : []}
        >
          {childLayout?.[0]?.pageContent?.[0]?.chartDetail
            ?.filter((item) => item.axis === 'x')
            .map((obj) => (
              <XAxis key={obj.fld_id} dataKey={obj.fld_id} tick={{ fontWeight: 'bold' }} />
            ))}
          <YAxis hide />
          <YAxis yAxisId="left" orientation="left" hide={true} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" align="right" layout="horizontal" height={36} />
          <ZAxis range={[0, 0]} />

          {item?.pageContent?.[0]?.chartDetail
            ?.filter((item) => item.axis === 'y')
            .map((obj, index) =>
              obj.kf_ty === 'bar' ? (
                <Bar
                  key={obj.fld_id}
                  barSize={10}
                  dataKey={obj.ttl}
                  fill={index == 0 ? '#1d2b71' : '#e941a5'}
                  label={{
                    position: 'top',
                    fontSize: 12,
                    fill: '#333',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
                  }}
                />
              ) : obj.kf_ty === 'line' ? (
                <Line
                  key={obj.fld_id}
                  type="monotone"
                  barSize={10}
                  strokeWidth={4}
                  dataKey={obj.ttl}
                  // fill="#f16a7e"
                  stroke={colors[index]}
                  yAxisId="left"
                >
                  {' '}
                  <LabelList
                    dataKey={obj.ttl}
                    content={
                      obj.is_percent_value == !null ? (
                        <CustomizedLabel />
                      ) : (
                        <CustomizedLabelNotpercent />
                      )
                    }
                  />
                </Line>
              ) : obj.kf_ty === 'dot' ? (
                <Scatter
                  key={obj.fld_id}
                  barSize={10}
                  dataKey={obj.ttl}
                  fill={index == 3 ? '#45a34b' : '#d63f4a'}
                  legendType={index == 3 ? 'circle' : 'diamond'}
                />
              ) : null
            )}
          {/* <CartesianGrid stroke="#f5f5f5" /> */}
        </ComposedChart>
      </ResponsiveContainer>

      {/* custome table down side */}
      <Box sx={{ marginRight: '0%', textAlign: 'start' }}>
        <table
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
            width: '100%'
          }}
        >
          <tbody>
            {chart_data?.chrt_id === 'flm_c_a2_m2_s4.t1_c1' ||
            chart_data?.chrt_id === 'c_a2_m2_s4.t1_c1' ? (
              <>
                <tr>
                  {chart_data?.chartTableData?.map((item, index) =>
                    index === 0 ? (
                      <th key={index} style={{ width: '16.666%' }}>
                        {item?.call_avg || item?.flm_call_avg ? (
                          <CircleIcon sx={{ color: '#45a34b', fontSize: '14px' }} />
                        ) : item?.flm_call_avg ? (
                          <CircleIcon sx={{ color: '#45a34b', fontSize: '14px' }} />
                        ) : (
                          ''
                        )}
                        {item?.call_avg || item?.flm_call_avg}
                      </th>
                    ) : (
                      <th key={index} style={{ width: '16.6666%' }}>
                        {' '}
                        {item?.call_avg || item?.flm_call_avg}
                      </th>
                    )
                  )}
                </tr>

                <tr>
                  {chart_data?.chartTableData?.map((item, index) =>
                    index === 0 ? (
                      <th key={index} style={{ width: '16.6666%' }}>
                        {item?.call_avg || item?.flm_call_avg ? (
                          <ImDiamonds style={{ color: '#d63f4a', fontSize: '14px' }} />
                        ) : item?.flm_call_avg ? (
                          <ImDiamonds style={{ color: '#d63f4a', fontSize: '14px' }} />
                        ) : (
                          ''
                        )}
                        {item?.call_avg || item?.flm_call_avg}
                      </th>
                    ) : (
                      <th key={index} style={{ width: '16.666%' }}>
                        {item?.wrk_dys || item?.flm_wrk_dys}
                      </th>
                    )
                  )}
                </tr>
              </>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </Box>

      {/* custom table belowe from line 171-199 */}
    </>
  );
};

ComposedCharts.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object,
  item: PropTypes.array
};

export default ComposedCharts;
