import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import LayoutItem from './LayoutItem';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { arr1 } from '../../features/counter/counterSlice';
import Container from '@mui/material/Container';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import { ToGetDropDownValueYear } from '../../features/counter/counterSlice';
import { ToGetDropDownValueMonth } from '../../features/counter/counterSlice';

// MUI icons
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DynamicLayout = ({
  pageContentObj,
  children,
  pageData,
  refetchPageData,
  targetPageId,
  pageSections,
  // oneLoginme,
  // fetch_user_detail,
  Page_a2_ApiCall
}) => {
  const [pageSectionData, setPageSectionData] = useState([]);
  const [check, setCheck] = useState([]);
  const [viewplrundata, SetViewPlrunData] = useState([]);
  const [gettablename, SetGetTableName] = useState([]);
  const [apidata, setApiData] = useState();

  const location = useLocation();
  // redux toolkit
  const count = useSelector((state) => state.counter.arr);
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  const dropDownValuesYear = useSelector((state) => state.counter.year);
  const dropDownValuesmonth = useSelector((state) => state.counter.month);
  const dropDownValuesMrName = useSelector((state) => state.counter.mrName);

  const dispatch = useDispatch();
  // console.log('hyperdata@...', hyperdata);

  useEffect(() => {
    // This code will run whenever the URL changes
    setCheck([]);
    SetViewPlrunData([]);
    if (hyperdata?.length) {
      RemoveProgressBarHyperlinkObject(hyperdata);
    }
    dispatch(ToGetDropDownValueMonth(''));
    dispatch(ToGetDropDownValueYear(''));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(ToGetDropDownValueYear(''));

    dispatch(ToGetDropDownValueMonth(''));
  }, [targetPageId]);

  /**
   * Parse API response to generate section content for the provided `sctn_id`
   * @param {*} sctn_id
   */

  const contentGenerator = useCallback(
    (sctn_id) => {
      let returnValue = [];
      if (pageContentObj) {
        returnValue = [
          ...pageContentObj
            .filter((contentObj) => contentObj?.pageData?.[0]?.sctn_id === sctn_id)
            .map((item) => item?.pageContent?.flat())
            .flat()
        ];
      }

      return sctn_id ? returnValue : [];
    },
    [pageContentObj]
  );

  const pageSectionStructureGenerator = useCallback(
    (pageSections) => {
      const pg_sctns = pageSections?.filter((item) => item?.pg_id === targetPageId);

      const sectionsMap = {};
      const result = [];

      // Create a map of menu items using their mnu_id
      for (const item of pg_sctns) {
        const { sctn_id } = item;
        sectionsMap[sctn_id] = { ...item, children: [], pageContent: [] };
      }
      // Iterate over the menu items again to build the hierarchy
      for (const item of pg_sctns) {
        const { prnt_sctn_id, sctn_id } = item;
        const sectionItem = sectionsMap[prnt_sctn_id];
        const pageContent = contentGenerator(sctn_id);

        sectionsMap[sctn_id].pageContent.push(...pageContent);
        if (sectionItem) {
          sectionItem.children.push(sectionsMap[sctn_id]);
        } else {
          result.push(sectionsMap[sctn_id]);
        }
      }
      return result;
    },
    [targetPageId]
  );

  useEffect(() => {
    if (targetPageId) {
      setPageSectionData(pageSectionStructureGenerator(pageSections));
    }
  }, [targetPageId, pageSections, pageSectionStructureGenerator, check]);

  // *redux part to store api data and use it , insted of api call for same data.
  useEffect(() => {
    if (count?.length) {
      const sorted_data = count?.filter((item) => item[targetPageId]);
      if (sorted_data.length) {
        setApiData(sorted_data[0][targetPageId]);
      } else {
        // oneLoginme();
        // fetch_user_detail()
        // const emp_id = localStorage.getItem('emp_id');
        // console.log('emp_id-Dynamic....', emp_id);
        const All_Data = Page_a2_ApiCall(targetPageId);
        All_Data.then((res) => setApiData(res));
        All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
        // Page_a2_ApiCall(targetPageId).then((res) => dispatch(arr1({ [targetPageId]: res })));
      }
    } else {
      // oneLoginme();
      // fetch_user_detail()
      // const emp_id = localStorage.getItem('emp_id');
      // console.log('emp_id-Dynamic....', emp_id);
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    }
  }, [targetPageId]);

  // *below useEffect Will be called when ever dropdownvalues changes , year and month dropdowns .
  useEffect(() => {
    if (dropDownValues && dropDownValues[0] !== '1') {
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    } else if (dropDownValuesYear) {
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    } else if (dropDownValuesmonth) {
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    }
  }, [dropDownValues, dropDownValuesYear, dropDownValuesmonth]);

  //useEffect to run api after mr_name drop value selected
  useEffect(() => {
    if (dropDownValuesMrName?.length) {
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    }
  }, [dropDownValuesMrName?.length]);

  // useEffect(() => {
  //     Page_a2_ApiCall(targetPageId).then((res) => setApiData(res));
  // }, [targetPageId]);

  // console.log('count...', count);

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  return (
    <>
      {pageSectionData ? (
        <>
          <Box sx={{ display: 'flex', marginLeft: '5%', marginRight: '5%' }}>
            <Container maxWidth="false">
              {children}
              {/* dynamic layout */}
              {
                // parent Layout
                pageSectionData?.map((pageSection) => {
                  return (
                    <React.Fragment key={pageSection?.sctn_id}>
                      <Accordion defaultExpanded={true} disableGutters expanded={true}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls={pageSection.sctn_id}
                          id={pageSection.sctn_id}
                          style={{ minHeight: '10px' }}
                        >
                          {hyperdata.length && pageSection?.sctn_id === 's1' ? (
                            <span>
                              <IoChevronBackCircleOutline
                                style={{ margin: 0, fontSize: '25px' }}
                                onClick={() => {
                                  RemoveProgressBarHyperlinkObject(hyperdata);
                                }}
                              />
                            </span>
                          ) : (
                            ''
                          )}

                          <span style={{ fontWeight: '700' }}>{pageSection?.lbl || ''}</span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <LayoutItem
                            layout={pageSection}
                            data={pageData}
                            refetchPageData={refetchPageData}
                            check={check}
                            setCheck={setCheck}
                            viewplrundata={viewplrundata}
                            SetViewPlrunData={SetViewPlrunData}
                            SetGetTableName={SetGetTableName}
                            gettablename={gettablename}
                            apidata={apidata}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })
              }
            </Container>
          </Box>
        </>
      ) : (
        <>No data found</>
      )}
    </>
  );
};

DynamicLayout.propTypes = {
  children: PropTypes.node,
  pageContentObj: PropTypes.array,
  pageData: PropTypes.array,
  refetchPageData: PropTypes.func,
  targetPageId: PropTypes.string,
  pageSections: PropTypes.array,
  Page_a2_ApiCall: PropTypes.func,
  oneLoginme: PropTypes.func,
  fetch_user_detail: PropTypes.func
};

export default memo(DynamicLayout);
