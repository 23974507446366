import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];
const StackedBarChart = ({ layout, apidata, content }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const ChartId = content?.chrt_id;
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[ChartId]);
      return filterd_data?.[0]?.[ChartId];
    }
  }, [apidata]);
  const colors = ['#FF5733', '#3366FF', '#33FF57', '#FF33A1'];
  return (
    <>
      <BarChart
        width={500}
        height={300}
        data={chart_data?.chartTableData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        {layout?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'x')
          .map((obj) => (
            <XAxis key={obj.fld_id} dataKey={obj.fld_id} />
          ))}
        <YAxis />
        <Tooltip />
        <Legend />
        {layout?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'y')
          .map((obj, index) => (
            <Bar
              key={obj.fld_id}
              dataKey={obj.fld_id}
              barSize={60}
              fill={colors[index]}
              stackId="a"
            />
          ))}
      </BarChart>
    </>
  );
};

export default StackedBarChart;

StackedBarChart.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
