/* eslint-disable no-unused-vars */
import { Stack, Button, IconButton, Tooltip } from '@mui/material';

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DELETE_SCENARIO, UPDATE_SCENARIO } from '../../graphql';
// import { utils, writeFileXLSX } from 'xlsx';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { camelCase } from 'lodash';
import Http from '../../utils/http';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

const DataTable = ({
  columns,
  tableHeading,
  refetchPageData = () => {},
  handleRefreshClick,
  tableName,
  check,
  setCheck,
  techName,
  pageId,
  dynamic_page,
  viewplrundata,
  SetViewPlrunData
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateScenario] = useMutation(UPDATE_SCENARIO);
  const [deleteScenario] = useMutation(DELETE_SCENARIO);
  const [memodata, setMemoData] = useState([]);
  const [storeFromData, setStoreFormData] = useState(null);
  const [editRowButoon, setEditRowButton] = useState(false);

  const handlefileupload = (event) => {
    const uploadFile = event.target.files[0];
    if (uploadFile) {
      const formData = new FormData();
      formData.append('uploadFile', uploadFile);
      readExcel(uploadFile);
      setStoreFormData(formData);
    }
  };

  const readExcel = (uploadFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      let shortcolumns = {};
      // Assuming the Excel file has only one sheet
      const sheetName = workbook.SheetNames[0];
      const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      const newArray = excelData.map((obj, i) => ({ ...obj, id: i.toString() }));
      const result = newArray.filter((i) => Object.keys(i).length > 2);

      for (const obj of columns) {
        shortcolumns[obj.headerName] = obj.field;
      }

      const datatablerow = [];
      let increment = 5;
      for (const i of result) {
        const entries = Object.entries(i);
        let objcs = {};
        for (const [key, value] of entries) {
          if (key in shortcolumns) {
            objcs[shortcolumns[key]] = value;
          }
        }
        increment += 1;
        datatablerow.push({ ...objcs, id: increment });
      }

      const lengths = {};

      // for (let key in columns) {
      //   let ll = []
      //   for (let x in columns[key]) {
      //     if (columns[key]['headerName'] !== "Created By" && columns[key]['headerName'] !== "Created On" && columns[key]['headerName'] !== "Changed By" && columns[key]['headerName'] !== "Changed On") {
      //       if (x === 'field' || x === 'lngth') {
      //         ll.push(columns[key][x])
      //       }
      //     }
      //   }
      //   ll[0] && ll[1] ? lengths[ll[0]] = ll[1] : null
      // }

      for (let key in columns) {
        let ll = [];
        for (let x in columns[key]) {
          if (x === 'field' || x === 'lngth') {
            ll.push(columns[key][x]);
          }
        }
        lengths[ll[0]] = ll[1];
      }

      const slicedArr = datatablerow.slice(1);

      let flag = true;
      for (let i in slicedArr) {
        for (let j in slicedArr[i]) {
          if (j !== 'id' && parseInt(slicedArr[i][j].length) <= parseInt(lengths[j])) {
            null;
          } else if (j !== 'id' && parseInt(slicedArr[i][j].length) >= parseInt(lengths[j])) {
            toast.error(
              `Column ${j} Row ${parseInt(i) + parseInt(3)} length must less than ${lengths[j]} `
            );
            flag = false;
            setStoreFormData(null);
          }
        }
      }

      if (flag) {
        toast.success('file uploaded successfully!');
        if (Array.isArray(memodata)) {
          setMemoData((prev) => [...prev, ...slicedArr]);
        } else {
          setMemoData(slicedArr);
        }
      }
    };
    reader.readAsBinaryString(uploadFile);
  };

  // belove line for data

  const queryVar = useMemo(() => {
    return camelCase(tableName) + 'List';
  }, [tableName]);

  // const dynamicApiForAllTable = async (tableTechName) => {
  //   try {
  //     const response = await Http.get(`/fetch-${tableTechName}-data`);
  //     if (!response.data?.error) {
  //       setMemoData(response?.data.data)
  //       console.log(tableName, response?.data.data)
  //     } else {
  //       toast.error(response.data?.error);
  //     }
  //   } catch (e) {
  //     toast.error(e?.message);
  //   }
  // }

  // calling post api for all table in once but value not in table (see and complite this*******)
  const dynamicApiForAllTablev1 = async (page_id, aa, bb) => {
    const emp_id = localStorage.getItem('emp_id');
    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: '',
        pg_id: 'a2_m2_hyp_cmpl',
        emp_id: `${emp_id}`,
        field_values: {},
        action: aa ? aa : {},
        sel_table: bb ? bb : {}
      });
      if (!response.data?.error) {
        const a1 = response?.data?.data?.allTablesData?.filter((i) => i?.[`${techName}`]);
        if (check?.['allTablesData']?.length >= 1) {
          null;
        } else {
          setMemoData(a1?.[0]?.[`${techName}`]);
        }
        if (aa && bb) {
          const b1 = response?.data?.data;
          setCheck(b1);
        }
      } else {
        toast.error(response.data?.error);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  useEffect(() => {
    dynamicApiForAllTablev1(pageId);
  }, [pageId, techName]);

  useEffect(() => {
    if (
      typeof check === 'object' &&
      !Array.isArray(check) &&
      check?.['allTablesData']?.length >= 1
    ) {
      const ss = check?.['allTablesData']?.filter((i) => i?.[techName]);
      setMemoData(ss?.[0]?.[techName]);
    } else if (viewplrundata?.length >= 1) {
      const ss = viewplrundata[0]['allTablesData']?.filter((i) => i?.[techName]);
      setMemoData(ss?.[0]?.[techName]);
    }
    // else if (viewplrundata?.length >= 0) {
    //   dynamicApiForAllTablev1(pageId);
    // }
    // else {
    //   dynamicApiForAllTablev1(pageId);
    // }
  }, [check, viewplrundata]);

  // const query = {     {/*first*/}
  //   query: {
  //     [queryVar]: columns.reduce(
  //       (acc, col) =>
  //         col?.field === 'vw_output'
  //           ? { ...acc }
  //           : {
  //             ...acc,
  //             [col?.field]: true
  //           },
  //       {
  //         id: true,
  //         // __args: {
  //         //   where: { id: 2 }  {rtigger || target  : plrun_id }
  //         // },
  //       }
  //     ),
  //   }
  // };

  // const dynamicQuery = jsonToGraphQLQuery(query, { pretty: true });  {/*2nd*/}

  {
    /*3rd*/
  }
  // const { data: graphqlData, loading } = useQuery(
  //   gql`
  //     ${dynamicQuery}
  //   `,
  //   {
  //     onError: (err) => toast.error(err.message)
  //   }
  // );

  // useEffect(() => {
  //   refetch()
  // }, [check])

  // const data = useMemo(() => graphqlData?.[`${queryVar}`], [graphqlData, queryVar]);    {/*4th*/}

  {
    /*5fth*/
  }
  // useMemo(() => {
  //   const newData = graphqlData?.[`${queryVar}`];
  //   if (check?.length >= 1 && techName === 'plrun') {
  //     setMemoData([{
  //       "id": 4,
  //       "client": "c001",
  //       "plrun_id": 777,
  //       "plrun_st_dt": "2023-09-03 16:30:39",
  //       "plrunprof_id": "FCFM1",
  //       "pln_cyc": "202308",
  //       "loc_id": "LOC1",
  //       "plrun_en_dt": "2023-09-03 16:30:42",
  //       "cr_dt": null,
  //       "ch_dt": null,
  //       "crusr_id": "Priya",
  //       "chusr_id": null
  //     }])
  //   } else {
  //     setMemoData(newData);
  //   }

  // }, [graphqlData, queryVar, check]);

  //  you'r here , value stored in reducer

  const rowUpdateHandler = (newRow, oldRow) => {
    console.log('newRow, oldRow', newRow, oldRow);
    return new Promise((resolve, reject) => {
      try {
        updateScenario({
          variables: {
            id: newRow?.id,
            client: newRow?.client,
            scn_id: newRow?.scn_id,
            scn_nm: newRow?.scn_nm,
            sscn_nm: newRow?.sscn_nm
          },
          onCompleted: () => {
            toast.success('Row updated successfully!');
            refetchPageData();
            handleRefreshClick();
          },
          onError: (error) => {
            toast.error(`An error occured.\n${error.message}`);
          }
        });
        resolve(newRow, oldRow);
      } catch (err) {
        reject(err);
      }
    });
  };

  {
    /*uncomment 256 to 261*/
  }
  // const rowDeleteHandler = useCallback(() => {
  //   deleteScenario({
  //     variables: {
  //       ids: selectedRows
  //     },
  //     onCompleted: () => {
  //       setSelectedRows([]);
  //       // toast.success(
  //       //   `${data?.length === selectedRows?.length
  //       //     ? 'All rows'
  //       //     : `Rows ${selectedRows?.join(', ')} were deleted`
  //       //   }`
  //       // );
  //       refetchPageData();
  //       // handleRefreshClick();
  //       setCheck([]);
  //     },
  //     onError: (error) => {
  //       toast.error(`An error occured.\n${error.message}`);
  //     }
  //   });
  // }, [selectedRows]);

  const rowDeleteHandler = async (page_id, techName) => {
    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: '',
        pg_id: `${page_id}`,
        field_values: [
          {
            field_name: 'id',
            field_value: `${selectedRows}`
          }
        ],
        action: {
          action_type: 'action',
          action_value: 'delete'
        },
        sel_table: { table_id: `${techName}` }
      });
      if (!response.data?.error) {
        toast.success('Row Deleted successfully!');
        setCheck([]);
      } else {
        toast.error(response.data?.error);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const CustomToolbar = () => {
    // const xlsxdata = [];
    // const header1 = [];
    // const header2 = [];

    // const downloadexcelfile = () => {
    //   columns.map((i) => header1.push(i['headerName']));
    //   columns.map((i) => header2.push(i['field']));
    //   xlsxdata.push(header1, header2)

    //   const workbook = XLSX.utils.book_new();
    //   const worksheet = XLSX.utils.aoa_to_sheet([...xlsxdata]);
    //   console.log('worksheet...', worksheet)

    //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //   const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    //   const url = URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = (tableName + '.xlsx');
    //   a.click();
    // };

    const writeExcelFile = async () => {
      // Create a new workbook and add a worksheet
      const a1 = [];
      const a2 = [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Data');

      columns.map((i) => a1.push(i['headerName']));
      columns.map((i) => a2.push(i['field']));

      // columns.map((i) => {
      //   if (i['headerName'] !== "Created By" && i['headerName'] !== "Created On" && i['headerName'] !== "Changed By" && i['headerName'] !== "Changed On") {
      //     a1.push(i['headerName'])
      //   }

      // });
      // columns.map((i) => {
      //   if (i['field'] !== 'crusr_id' && i['field'] !== 'cr_dt' && i['field'] !== 'chusr_id' && i['field'] !== 'ch_dt') {
      //     a2.push(i['field'])
      //   }

      // });

      // Add headers to the worksheet
      // worksheet.addRow(a1);
      worksheet.addRow(a1);
      worksheet.addRow(a2);
      const validations = [];
      columns.map((i) =>
        validations.push({
          type:
            i['dty'] === 'varchar'
              ? 'textLength'
              : i['dty'] === 'datetime'
              ? 'date'
              : i['dty'] === 'decimal'
              ? 'decimal'
              : i['dty'] === 'int'
              ? 'whole'
              : null,
          showErrorMessage: true,
          operator: 'lessThan',
          formulae: [5],
          errorTitle: 'Invalid Input',
          error: `enterd value must be ${i['dty']}`
        })
      );

      // toast.error('erros are appearnig');

      // to get excel alphabets like  A to XFD
      const excelcolumns = [];
      for (let i = 0; i <= 500; i++) {
        const colName = XLSX.utils.encode_col(i);
        excelcolumns.push(colName);
      }

      columns.map((i, index) => {
        if (
          i['headerName'] !== 'Created By' &&
          i['headerName'] !== 'Created On' &&
          i['headerName'] !== 'Changed By' &&
          i['headerName'] !== 'Changed On' &&
          i['dty'] !== 'datetime'
        ) {
          worksheet.dataValidations.add(`${excelcolumns[index]}3:${excelcolumns[index]}9999`, {
            type:
              i['dty'] === 'varchar'
                ? 'textLength'
                : i['dty'] === 'decimal'
                ? 'decimal'
                : i['dty'] === 'int'
                ? 'whole'
                : null,
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            errorTitle: 'Invalid Input',
            error:
              i['dty'] === 'varchar'
                ? 'Varchar only'
                : i['dty'] === 'decimal'
                ? 'Decimal only'
                : i['dty'] === 'int'
                ? 'Integer only'
                : null
          });
        }
      });

      // Save the workbook to a buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Create a blob from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Save the blob as a file
      const fileName = tableName + '.xlsx';
      saveAs(blob, fileName);
    };

    const saveAs = (blob, fileName) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    };

    const SaveRowData = async () => {
      if (storeFromData) {
        try {
          const response = await Http.post('/upload-scenario-data', storeFromData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          });
          if (response) {
            toast.success('file uploaded successfully!');
            handleRefreshClick();
            setStoreFormData(null);
          } else {
            toast.error(response.data?.error);
          }
        } catch (e) {
          console.error('File upload error:', e);
          toast.error(e?.message);
        }
      }
    };

    return (
      <>
        <GridToolbarContainer sx={{ justifyContent: 'space-between', mt: 1, mx: 1 }}>
          {tableHeading}
          <Stack direction="row" alignItems="center" spacing={1}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <Button
              size="small"
              startIcon={<FileUploadOutlinedIcon fontSize="small" />}
              component="label"
            >
              Upload
              <input
                hidden
                type="file"
                onChange={handlefileupload}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Button>

            <Button
              size="small"
              startIcon={<FileDownloadOutlinedIcon fontSize="small" />}
              component="label"
              onClick={writeExcelFile}
            >
              Template
            </Button>

            {storeFromData ? (
              <Button
                size="small"
                startIcon={<SaveOutlinedIcon fontSize="small" />}
                component="label"
                color="success"
                onClick={SaveRowData}
              >
                Save
              </Button>
            ) : null}

            {editRowButoon ? (
              <Button
                size="small"
                startIcon={<SaveOutlinedIcon fontSize="small" />}
                component="label"
                color="success"
                // onClick={SaveRowData}
              >
                saveRow
              </Button>
            ) : null}

            <GridToolbarExport />
            {selectedRows.length ? (
              <Tooltip arrow title="Delete selected rows">
                <IconButton
                  color="error"
                  onClick={() => {
                    rowDeleteHandler(pageId, techName);
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        </GridToolbarContainer>
      </>
    );
  };

  const onCellClick = async (event) => {
    // Use the row data as needed
    if (event.formattedValue === 'no') {
      dynamicApiForAllTablev1(
        pageId,
        {
          action_type: 'table_id',
          action_value: 'selected_row'
        },
        {
          table_id: `${techName}`,
          field_name: 'plrun_id',
          field_value: `${event.row['plrun_id']}`
        }
      );
    } else {
      setCheck([]);
    }
  };
  const handleRowDoubleClick = (params) => {
    setEditRowButton(true);
    const { row } = params;
    console.log('Row double-clicked:', row);

    // Perform actions based on the clicked row
  };

  return (
    <DataGrid
      rows={memodata || []}
      columns={columns}
      // loading={loading}  {/*uncomment here*/}
      disableRowSelectionOnClick
      slots={{ toolbar: CustomToolbar }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10
          }
        }
      }}
      checkboxSelection={true}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setSelectedRows(newRowSelectionModel);
      }}
      onCellClick={onCellClick}
      onRowDoubleClick={handleRowDoubleClick}
      pageSizeOptions={[10, 25, 50, 100]}
      processRowUpdate={rowUpdateHandler}
      onProcessRowUpdateError={(err) => {
        toast.error('An error occured while updating datatable.');
        console.error('datatable error', err?.message);
      }}
    />
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      valueGetter: PropTypes.func,
      valueFormatter: PropTypes.func,
      renderCell: PropTypes.func,
      headerName: PropTypes.string
    })
  ),
  tableHeading: PropTypes.node,
  refetchPageData: PropTypes.func,
  tableName: PropTypes.string,
  handleRefreshClick: PropTypes.func,
  check: PropTypes.array,
  setCheck: PropTypes.func,
  techName: PropTypes.any,
  pageId: PropTypes.any,
  dynamic_page: PropTypes.any,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func
};

export default DataTable;
