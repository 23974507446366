import * as React from 'react';
import { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export default function BasicTable({ layout, apidata, content }) {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      // console.log('layout?.sctn_id', layout?.sctn_id, layout, filterd_data);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  let rowkeys = [];
  if (chart_data?.[0]) {
    rowkeys = Object.keys(chart_data?.[0]);
  }

  return (
    <TableContainer component={Paper} sx={{ height: 'auto' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {layout?.pageContent?.[0]?.tableFieldData.map((item, index) => (
              <>
                {' '}
                {index === 0 ? (
                  <TableCell align="start" sx={{ fontWeight: 'bold' }}>
                    {item?.fld_l}
                  </TableCell>
                ) : (
                  <TableCell align="start" sx={{ fontWeight: 'bold' }}>
                    {item?.fld_l}
                  </TableCell>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chart_data?.map((item) => (
            <>
              <TableRow
                key={item.party_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {rowkeys?.map((child) => (
                  <>
                    <TableCell align="start">{item[child]}</TableCell>
                  </>
                ))}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
