import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

const VerticalBarChart = ({ apidata, item, chrt_id }) => {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata, chrt_id]);
  const colors = ['#FF5733', '#3366FF', '#33FF57', '#FF33A1'];

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  // console.log('data@', chart_data, q);
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={730}
        height={250}
        data={q || []}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'x')
          .map((obj) => (
            <XAxis key={obj.fld_id} dataKey={obj.fld_id} />
          ))}
        <YAxis />
        <Tooltip />
        <Legend />
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'y')
          .map((obj, index) => (
            <Bar key={obj.fld_id} dataKey={obj.ttl} barSize={60} fill={colors[index]} />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarChart;
VerticalBarChart.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  item: PropTypes.object,
  chrt_id: PropTypes.string
};
