import { Button, Container, IconButton, Stack, TextField, Typography } from '@mui/material';
import GuestLayout from '../../layouts/GuestLayout/GuestLayout';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Http from '../../utils/http';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../components/AuthService/AuthService';

const Login = () => {
  /**
   * state declarations
   */
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const userLogin = async (values) => {
  //   setLoading(true);
  //   try {
  //     const response = await Http.post('login', values);
  //     if (!response.data.error) {
  //       const userData = response.data;
  //       const accessToken = userData?.access_token?.original?.access_token;
  //       const expiry = Date.now() + (userData?.access_token?.original?.expires_in || 0) * 1000;
  //       // store the access token and expiry date
  //       localStorage.setItem('access_token', accessToken);
  //       localStorage.setItem('expiry', expiry);
  //       localStorage.setItem('user_data', JSON.stringify(userData));
  //       // set the authorization HTTP headers so that future api calls are authorized
  //       Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  //       navigate('/');
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const Login_To_Redirection = async (values) => {
    setLoading(true);
    try {
      if (localStorage.getItem('emp_id')) {
        localStorage.removeItem('emp_id');
        localStorage.removeItem('emp_name');
        localStorage.removeItem('SSOlogin');
      }
      const response = await Http.post('login', values);
      if (!response.data.error) {
        const userData = response?.data;
        const emp_id = userData?.data?.emp_id;
        const emp_name = userData?.data?.emp_name;
        localStorage.removeItem('emp_id');
        localStorage.removeItem('emp_name');
        localStorage.removeItem('SSOlogin');
        localStorage.setItem('emp_id', emp_id);
        localStorage.setItem('emp_name', emp_name);
        let menuDataString = JSON.stringify(userData?.data?.menu_items);
        localStorage.setItem('MenuData', menuDataString);
        navigate('/');
        // navigate('app/a2/kpi-dashboard');
      } else {
        toast.error(response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * formik initialization
   */
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Please enter your email'),
      password: Yup.string().required('Please enter password')
    }),
    onSubmit: (values, { resetForm }) => {
      // userLogin(values);
      Login_To_Redirection(values);
      resetForm();
    }
  });

  // sso login
  const handleLogin = async () => {
    localStorage.removeItem('emp_id');
    localStorage.removeItem('emp_name');
    localStorage.removeItem('SSOlogin');
    localStorage.setItem('SSOlogin', 'SSOlogin');
    await AuthService.login();
  };

  return (
    <GuestLayout>
      <Container maxWidth="sm">
        <Stack component="form" noValidate spacing={2} onSubmit={formik.handleSubmit}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Login to your account
          </Typography>
          <TextField
            label="Email"
            type="email"
            name="email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <TextField
            label="Password"
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            helperText={formik.touched.password && formik.errors.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Login
          </LoadingButton>
          <LoadingButton variant="contained" type="submit" onClick={handleLogin} loading={loading}>
            SSO Login
          </LoadingButton>
          <Button onClick={() => navigate('/register')}>New here? Register</Button>
        </Stack>
      </Container>
    </GuestLayout>
  );
};

export default Login;
