import React from 'react';
// import Http from '../../utils/http';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const T4 = ({ chrt_id, apidata }) => {
  // const [apiData, setApiData] = useState([]);

  // const Page_a2_ApiCall = async () => {
  //   try {
  //     const emp_id = localStorage.getItem('emp_id');
  //     const response = await Http.post('/fetch-all-page-data', {
  //       a_id: 'a2',
  //       pg_id: `${layout?.pg_id}`,
  //       emp_id: `${emp_id}`,
  //       field_values: {},
  //       action: {},
  //       sel_table: {}
  //     });
  //     if (!response.data?.error) {
  //       setApiData(response?.data?.data?.chart);
  //     }
  //   } catch (e) {
  //     console.log(e?.message);
  //   }
  // };

  // useEffect(() => {
  //   Page_a2_ApiCall();
  // }, []);

  const filteredArray = apidata?.chart?.filter((item) => `${chrt_id}` in item);
  const card = (
    <React.Fragment>
      <CardContent
        style={{
          paddingLeft: '35px',
          paddingRight: '35px',
          paddingBottom: '16px',
          backgroundColor: '#D6EEEF'
        }}
      >
        {filteredArray?.[0]?.[chrt_id]?.['chartParams']
          ? filteredArray?.[0]?.[chrt_id]?.['chartParams']?.map((item, index) =>
              /^[A-Za-z\s]+$/.test(item?.chartParamsValue?.[0]?.prm_val) ? (
                <Typography
                  key={index}
                  sx={{
                    fontSize: '1.3rem',
                    color: 'black',
                    fontWeight: '800',
                    textAlign: 'center'
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item?.chartParamsValue?.[0]?.prm_val}
                </Typography>
              ) : (
                <Typography
                  key={index}
                  sx={{
                    fontSize: '1.2rem',
                    color: '#1d2b71',
                    fontWeight: '700',
                    textAlign: 'center'
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item?.chartParamsValue?.[0]?.prm_val}
                </Typography>
              )
            )
          : null}
      </CardContent>
    </React.Fragment>
  );

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '12px',
          height: '99px',
          backgroundColor: '#D6EEEF',
          width: 'fit-content',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)'
          }
        }}
      >
        {card}
      </Card>
    </Box>
  );
};

T4.propTypes = {
  chartParams: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object
};

export default T4;
