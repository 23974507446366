import { Box } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
const MuiTextTypo = ({ apidata, content, layout }) => {
  const dispatch = useDispatch();
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const chart_data = useMemo(() => {
    if (content) {
      const labels_id = content?.label?.[0]?.lbl_id;
      const api_data = apidata?.labels;
      if (labels_id !== 'l_blank') {
        let filterd_data = api_data?.filter((items) => items?.lbl_id === labels_id);
        return filterd_data?.[0]?.lbl_descr;
      }
    }
  }, [apidata]);

  const TextColoring = {
    color: content?.label?.[0]?.lbl_id === 'lbl_3' ? '#FFA500' : '#000000',
    cursor: content?.label?.[0]?.hpr_pg_id !== null ? 'pointer' : 'default'
  };

  // redux part for hyperlink
  const handleClickOpen = () => {
    if (content?.label?.[0]?.hpr_pg_id) {
      // below condition is for empty redux state
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: content?.label?.[0]?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        gutterBottom
        sx={{ ...TextColoring }}
        onClick={() => handleClickOpen(chart_data)}
        fontWeight="bold"
      >
        {chart_data}
      </Typography>
    </Box>
  );
};

export default MuiTextTypo;
MuiTextTypo.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
