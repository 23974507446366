import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MrtTable = ({ layout, apidata, content }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  const columns = useMemo(() => {
    return content?.tableData?.map((item, index) => ({
      accessorKey: item.fld_id,
      header: content?.tableFieldData?.[index]?.fld_l,
      textColor: item?.text_color,
      bgColor: item?.bg_color,
      icon: item?.icon,
      columnHeaderColor: item?.col_hdr_bg_colr,
      columnHeaderTextColor: item?.col_hdr_text_colr,
      Cell: ({ cell }) => {
        const textColor = cell?.column?.columnDef?.textColor; //column data
        const styling = cell?.row?.original; //row data
        const icon = cell?.column?.columnDef?.icon;
        return (
          <div
            style={{
              color: styling[textColor] ? styling[textColor] : '#000000'
              // padding: '0.5em'
            }}
          >
            <Stack direction="row">
              <Typography fontWeight="600" fontSize="0.8rem">
                {cell.getValue()}
              </Typography>
              {styling[icon] === 'up' ? (
                <ArrowDropUpOutlinedIcon />
              ) : styling[icon] === 'down' ? (
                <ArrowDropDownIcon />
              ) : null}
            </Stack>
          </div>
        );
      }
    }));
  }, []);
  const shouldRemoveBorder = (cellValue) => {
    // Check if the cell value is empty
    return cellValue === '';
  };

  const shouldHaveBackgroundColor = (cell) => {
    const bgColor = cell?.column?.columnDef?.bgColor; //column data
    const styling = cell?.row?.original; //row data
    return styling[bgColor];
  };

  const shouldHaveColumnStyle = (cell) => {
    const bgColor = cell?.column?.columnDef?.columnHeaderColor;
    const textColor = cell?.column?.columnDef?.columnHeaderTextColor;
    //column data
    return {
      sx: {
        fontSize: '0.8rem',
        backgroundColor: bgColor ? '#1995AD' : '#FFFFFF',
        height: '80px',
        borderRight: '0.5px solid #E0E0E0',
        color: textColor ? textColor : '#000000',
        // padding: '0.5em'
        fontWeight: 'bold'
      }
      // Add more dynamic props here based on conditions
    };
  };

  const table = useMaterialReactTable({
    columns,
    data: chart_data || [],
    enableExpandAll: false, // Hide expand all double arrow in column header
    enableExpanding: false,
    filterFromLeafRows: true, // Apply filtering to all rows instead of just parent rows
    enableColumnFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    getSubRows: (row) => row.subRows || [], // Ensure all rows have sub-rows
    initialState: { expanded: true, density: 'compact' }, // Expand all rows by default
    paginateExpandedRows: false, // When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableGlobalFilter: false, // Hide global filter bar
    enableTopToolbar: false,
    enablePagination: false,
    // enableColumnResizing: true,
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        borderBottom: shouldRemoveBorder(cell.getValue()) ? 'none' : undefined,
        // height: '40px',
        border: '0.2px solid #1995AD',
        backgroundColor: shouldHaveBackgroundColor(cell) ? '#F9D23C' : '#FFFFFF',
        fontWeight: 'bold',
        whiteSpace: 'noraml',
        wordBreak: 'break-word'
      }
    }),
    muiTableHeadCellProps: shouldHaveColumnStyle
  });

  return <MaterialReactTable table={{ ...table }} />;
};

export default MrtTable;

MrtTable.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};
