import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';

const Charts = ({ chrt_id, apidata, layout }) => {
  const filteredArray = apidata?.chart?.filter((item) => `${chrt_id}` in item);
  const emp_id = localStorage.getItem('emp_id');
  const emp_name = localStorage.getItem('emp_name');
  const dispatch = useDispatch();
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  // console.log('chrt_id@@@', chrt_id, apidata, filteredArray);

  // redux part for hyperlink
  const handleClickOpen = (item) => {
    // let DynamicObjectForBar = {
    //   main_tab: layout?.lbl,
    //   sub_tab: item?.lbl,
    //   parameter_key_name: chart_data?.chartDetail?.[1]?.fld_id,
    //   parameter_key_value: data?.[chart_data?.chartDetail?.[1]?.fld_id]
    // };
    if (item?.hpr_pg_id) {
      // below condition is for empty redux state
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: item?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
      // dispatch(ProgressBarHyperLink({ ['BarInfo']: DynamicObjectForBar }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: '#E1EBEE',
          height: 'auto',
          borderRadius: '20px',
          position: 'relative',
          p: 2
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: 600, fontSize: '2.5rem', marginBottom: '0px' }}
                >
                  {
                    filteredArray?.[0]?.[chrt_id]?.chartParams?.[0]?.['chartParamsValue']?.[0]?.[
                      'prm_val'
                    ]
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: 'pointer', color: 'red', fontWeight: 900 }} variant="h6">
                  {`${emp_name} - ${emp_id}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                component="div"
                color="#0b5cab"
                sx={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => {
                  handleClickOpen(filteredArray?.[0]?.[chrt_id]?.chartParams?.[1]);
                }}
              >
                {
                  filteredArray?.[0]?.[chrt_id]?.chartParams?.[1]?.['chartParamsValue']?.[0]?.[
                    'prm_val'
                  ]
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          container
          sx={{ position: 'absolute', justifyContent: 'space-between', padding: '1rem' }}
        >
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ whiteSpace: 'nowrap', fontSize: 'vw'}}>
              
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" sx={{ textAlignLast: 'end', fontSize: '2.5vw' , position: 'absolute'}}>
              {
                filteredArray?.[0]?.[chrt_id]?.chartParams?.[1]?.['chartParamsValue']?.[0]?.[
                  'prm_val'
                ]
              }
            </Typography>
          </Grid> */}
        {/* <Typography
          variant="h6"
          sx={{
            position: 'absolute',
            marginTop: '10vh',
            color: 'red',
            fontWeight: 'bold'
          }}
        >
          {filteredArray?.[0]?.[chrt_id]?.chartParams?.[2]?.['chartParamsValue']?.[0]?.['prm_val']}
        </Typography>
        </Grid> */}
      </Box>
    </>
  );
};
Charts.propTypes = {
  chartParams: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object
};

export default Charts;
