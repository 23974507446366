import { useParams } from 'react-router-dom';
import BreadCrumbsNav from '../../components/Navbar/BreadCrumbsNav';
import Navbar from '../../components/Navbar/Navbar';
import { useEffect, useState } from 'react';
import Http from '../../utils/http';
import { Container, Toolbar } from '@mui/material';

const AppHeader = () => {
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState(null);
  const [menu, setMenu] = useState(null);

  const { appId, slug } = useParams();

  const fetchApps = async () => {
    setLoading(true);
    try {
      const response = await Http.get('get-client-apps');
      if (!response.data?.error) {
        setApps(response.data?.data);
      } else {
        console.error(response.data?.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchMenu = async () => {
    const emp_id = localStorage.getItem('emp_id');
    setLoading(true);
    try {
      const response = await Http.post('get-client-app-menu', { emp_id: `${emp_id}` });
      if (!response.data?.error) {
        setMenu(response.data?.data);
      } else {
        console.error(response.data?.error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
    fetchMenu();
  }, []);

  return (
    <>
      {apps && menu ? (
        <Container maxWidth="100%">
          <Navbar appName={apps?.find((app) => app?.a_id === appId)?.a_nm || ''} menu={menu} />
          <Toolbar id="1st" />
          <Toolbar sx={{ display: { xs: 'none', sm: 'inherit' } }} id="2nd" />
          <BreadCrumbsNav
            pageTitle={menu?.find((item) => item?.slug === slug)?.mnu_nm || 'Homeddsds page'}
          />
        </Container>
      ) : loading ? (
        <>Loading</>
      ) : null}
    </>
  );
};

export default AppHeader;
