import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts';
import PropTypes from 'prop-types';
import DoubleHorizontalBar from './DoubleHorizontalBar';
import Grid from '@mui/material/Grid';

const HorizontalBar = ({ chrt_id, apidata, item }) => {
  // console.log('item@...', item, chrt_id);
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  //   const data = [
  //     { name: 'GP', pv: 57 },
  //     { name: 'PHYSICIAN', pv: 21 },
  //     { name: 'PEDIATRICIAN', pv: 20 },
  //     { name: 'GYNECOLOGIST', pv: 16 },
  //     { name: 'DENTIST', pv: 14 },
  //     { name: 'DIABETIC SPECIALIST', pv: 10 },
  //     { name: 'ORTHOPEDIC', pv: 9 },
  //     { name: 'MBBS GP', pv: 5 },
  //     { name: 'ENT SURGEON', pv: 5 },
  //     { name: 'SURGEON', pv: 3 },
  //     { name: 'GYNEC-IVF', pv: 3 },
  //     { name: 'DERMATOLOGIST', pv: 3 },
  //     { name: 'GP-GYNE', pv: 2 },
  //     { name: 'ENT', pv: 2 },
  //     { name: 'CARDIOLOGIST', pv: 2 }
  //   ];

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.children?.[0]?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j?.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  // console.log('chart_data@', chart_data, chrt_id, q, item);
  const CustomYAxisTick = ({ x, y, payload }) => (
    <text
      x={x - 100}
      y={y}
      textAnchor="center"
      dy={3}
      fontSize="10px"
      fill="#000000"
      fontWeight="500"
    >
      {payload.value}
    </text>
  );
  CustomYAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
    chrt_id: PropTypes.string,
    item: PropTypes.object,
    itemData: PropTypes.object
  };

  return (
    <>
      {/* <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ fontSize: '1rem', textAlign: 'center' }}>{chart_data.ttl}</Box>
          <Box sx={{ height: '550', width: '100%', overflow: 'scroll' }}>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={q || []}
                layout="vertical"
                margin={{ left: 50, right: 30, top: 20, bottom: 10 }}
              >
                <XAxis hide type="number" />
                <YAxis
                  type="category"
                  dataKey={chart_data?.chartDetail?.[1]?.['ttl'] || 'no name'}
                  tickLine={false}
                  axisLine={false}
                  tick={{ fontSize: '0.7rem', fill: '#000000', textAnchor: 'end' }}
                  interval={0}
                />

                <Bar
                  dataKey={chart_data?.chartDetail?.[0]?.['fld_id'] || 'no name'}
                  fill="#6082B6"
                  minPointSize={0}
                  barSize={13}
                  barGap={3}
                  barCategoryGap={5}
                  stroke="#000000"
                  strokeWidth={1}
                >
                  <LabelList
                    dataKey="act_doc_ct"
                    position="right"
                    fill="#00000"
                    fontSize={'0.75rem'}
                    formatter={(value) => (value !== 0 ? value : null)}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ fontSize: '1rem', textAlign: 'center' }}>Text2</Box>
          <Box></Box>
          <Box>
            <DoubleHorizontalBar apidata={apidata} item={item} />
          </Box>
        </Box>
      </Box> */}

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography fontSize="12px" fontWeight="bold" textAlign="center">
              {item?.children?.[0]?.lbl || 'No label'}
            </Typography>
            <Typography color="transparent">testing</Typography>
            <Box overflow="scroll">
              <ResponsiveContainer height={350} width="100%">
                <BarChart
                  data={q || []}
                  layout="vertical"
                  margin={{ left: 50, right: 10, top: 20, bottom: 10 }}
                >
                  <XAxis hide type="number" />
                  <YAxis
                    type="category"
                    dataKey={chart_data?.chartDetail?.[1]?.['ttl'] || 'no name'}
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomYAxisTick />}
                    interval={0}
                  />
                  <Bar
                    dataKey={chart_data?.chartDetail?.[0]?.['fld_id'] || 'no name'}
                    fill="#6082B6"
                    minPointSize={0}
                    barSize={13}
                    barGap={1}
                    barCategoryGap={5}
                    stroke="#000000"
                    strokeWidth={1}
                  >
                    <LabelList
                      dataKey={chart_data?.chartDetail?.[0]?.['fld_id']}
                      position="right"
                      fill="#00000"
                      fontSize={'0.75rem'}
                      formatter={(value) => (value !== 0 ? value : null)}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <span
              style={{
                position: 'relative',
                bottom: '3px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '12px'
              }}
            >
              {item?.children?.[1]?.lbl}
            </span>

            <Grid container spacing={1}>
              {item?.children?.[1]?.pageContent?.map((itemData) => (
                <>
                  <Grid item xs={4} key={itemData?.chrt_id}>
                    {/* {index === 0 ? (
                      <Typography fontSize="12px" fontWeight="bold" textAlign="center">
                        {item?.children?.[1]?.lbl || 'No label'}
                      </Typography>
                    ) : (
                      <Typography color="transparent" fontSize="12px">
                        test
                      </Typography>
                    )} */}
                    <Typography textAlign="center" fontSize="12px">
                      {itemData?.chartDetail?.[0]?.ttl}
                    </Typography>
                    <DoubleHorizontalBar
                      apidata={apidata}
                      item={item}
                      itemData={itemData}
                      chrt_id={itemData?.chrt_id}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HorizontalBar;
HorizontalBar.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  chrt_id: PropTypes.string,
  item: PropTypes.object
};
