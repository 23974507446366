import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  createHttpLink
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { store } from './app/store';
import { Provider } from 'react-redux';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  // uri: 'https://lcp.estonetech.in/graphql',
  link: ApolloLink.from([
    errorLink,
    new createHttpLink({
      uri: 'https://lcp.estonetech.in/graphql'
    })
  ]),
  cache: new InMemoryCache(),
  credentials: 'include'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ErrorBoundary fallback="There was an error">
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </ApolloProvider>
  </React.StrictMode>
);
