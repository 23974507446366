import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function SelectSmall({ fld_id, fld_l, apidata }) {
  const [age, setAge] = React.useState('');
  const filteredArray = apidata?.fields?.filter((item) => item?.fld_id === `${fld_id}`);
  const DropValues = filteredArray?.[0]?.dropdown?.map((item) => item.display_name);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box
      sx={{
        border: '1px dashed black',
        // width: 'fit-Content',
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: '100%'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{fld_l}</Typography>
      </Stack>

      <FormControl sx={{ minWidth: '-webkit-fill-available' }} size="small">
        <InputLabel id="demo-select-small-label">{fld_l}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}
SelectSmall.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object
};
