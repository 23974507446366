import { createSlice } from '@reduxjs/toolkit';

function getCurrentWeekOfMonth() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const startOfWeek = firstDayOfMonth.getDay(); // Day of the week the month starts on (0 - 6)

  // Calculate which week of the month the current date falls into
  const weekOfMonth = parseInt((currentDay + startOfWeek) / 7);

  return weekOfMonth;
}
const weekOfMonth = getCurrentWeekOfMonth();

const initialState = {
  value: 0,
  value1: 0,
  arr: [],
  hyperlink_hpr_pg_id: [],
  getDropDownValue: [weekOfMonth],
  year: [],
  month: [],
  mrName: []
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value1 += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    arr1: (state, action) => {
      state.arr = [...state.arr, action.payload];
    },
    ProgressBarHyperLink: (state, action) => {
      state.hyperlink_hpr_pg_id = [...state.hyperlink_hpr_pg_id, action.payload];
    },
    RemoveProgressBarHyperLink: (state) => {
      // state.hyperlink_hpr_pg_id = [...state.hyperlink_hpr_pg_id, action.payload];
      state.hyperlink_hpr_pg_id = [...state.hyperlink_hpr_pg_id.splice(1, 1)];
      // console.log('state.hyperlink_hpr_pg_id...', state.hyperlink_hpr_pg_id, action.payload);
    },
    ToGetDropDownValue: (state, action) => {
      state.getDropDownValue = [action.payload];
    },
    ToGetDropDownValueYear: (state, action) => {
      state.year = [...action.payload];
    },
    ToGetDropDownValueMonth: (state, action) => {
      state.month = [...action.payload];
    },
    ToGetDropDownValueMrName: (state, action) => {
      state.mrName = [...action.payload];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  arr1,
  ProgressBarHyperLink,
  RemoveProgressBarHyperLink,
  ToGetDropDownValue,
  ToGetDropDownValueYear,
  ToGetDropDownValueMonth,
  ToGetDropDownValueMrName
} = counterSlice.actions;

export default counterSlice.reducer;
