import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';

function LinearProgressWithLabel({ value, index, chart_data, data, layout, item }) {
  const allcolors = ['primary', 'secondary', 'error', 'warning', 'info', 'success', 'inherit'];
  const dispatch = useDispatch();
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const handleClickOpen = () => {
    let DynamicObjectForBar = {
      main_tab: layout?.lbl,
      sub_tab: item?.lbl,
      parameter_key_name: chart_data?.chartDetail?.[1]?.fld_id,
      parameter_key_value: data?.[chart_data?.chartDetail?.[1]?.fld_id]
    };
    if (chart_data?.chartDetail?.[0]?.hpr_pg_id) {
      // below condition is for empty redux state
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: chart_data?.chartDetail?.[0]?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
      dispatch(ProgressBarHyperLink({ ['BarInfo']: DynamicObjectForBar }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  const FieldWiseLabel = chart_data?.chartDetail?.[1]?.fld_id;
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <Box sx={{ minWidth: 35, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ padding: '0.5rem' }}>
              <Typography fontWeight="bold" color="text.secondary">
                {data?.[FieldWiseLabel]}
              </Typography>
              {/* <Typography>
                {chart_data?.chartTableData?.[index]?.['cmplc_doc_ct']}/
                {chart_data?.chartTableData?.[index]?.['asso_doc_ct']}
              </Typography> */}
            </Box>
            <Box sx={{ alignSelf: 'center', marginRight: '5rem' }}>
              <Typography variant="body2" color="text.secondary" fontWeight="bold">{`(${Math.round(
                value
              )}%)`}</Typography>
            </Box>
          </Box>

          <Tooltip title={'Compliance %' + data?.['Compliance Assosiated %']}>
            <LinearProgress
              onClick={() => handleClickOpen(chart_data)}
              variant="determinate"
              sx={{ height: '10px', backgroundColor: 'white', cursor: 'pointer' }}
              // {...props}
              value={value}
              color={allcolors[index]}
            />
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  index: PropTypes.number,
  chart_data: PropTypes.object,
  data: PropTypes.array,
  apidata: PropTypes.array,
  layout: PropTypes.object,
  item: PropTypes.object
};

export default function GraphTest({ graphdata, chart_data, apidata, layout, item }) {
  // console.log('graphdata', graphdata, chart_data);

  return (
    <>
      <Stack sx={{ width: '100%', height: '330px', overflowY: 'auto' }} spacing={1}>
        {graphdata?.map((data, index) => (
          <LinearProgressWithLabel
            key={index}
            value={data?.[Object.keys(data)[0]]}
            index={index}
            chart_data={chart_data}
            data={data}
            apidata={apidata}
            layout={layout}
            item={item}
          />
        ))}
      </Stack>
    </>
  );
}

GraphTest.propTypes = {
  graphdata: PropTypes.array,
  chart_data: PropTypes.object,
  apidata: PropTypes.array,
  layout: PropTypes.object,
  item: PropTypes.object
};
