import { TextField } from '@mui/material';
import { object } from 'prop-types';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

const CustomizedField = ({ field, ...rest }) => {
  return (
    <Box
      sx={{
        border: '1px dashed black',
        backgroundColor: '#f5f5f5',
        padding: '3px'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{field?.fld_l}</Typography>
      </Stack>
      <TextField
        type={field?.dty}
        label={field?.fld_l}
        sx={{ width: 155, minWidth: '-webkit-fill-available' }}
        inputProps={{ maxLength: field?.lngth }}
        helperText={rest?.value?.length ? `${rest?.value?.length} of ${field?.lngth}` : null}
        {...rest}
      />
    </Box>
  );
};

CustomizedField.propTypes = {
  field: object.isRequired
};

export default CustomizedField;
