import React from 'react';
// import Container from '@mui/material/Container';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';

const Tiels = ({ chrt_id, apidata, layout }) => {
  const dispatch = useDispatch();
  const filteredArray = apidata?.chart?.filter((item) => `${chrt_id}` in item);
  // const isFirstTileDoctorCoverage = filteredArray?.[0]?.[chrt_id]?.['chartParams']?.some(
  //   (item) => item?.chartParamsValue?.[0]?.prm_val === 'Doctor Coverage'
  // );
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const chart_data = useMemo(() => {
    if (chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  const handleClickOpen = (chart_data) => {
    if (chart_data?.hpr_pg_id) {
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: chart_data?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  const Tilescoloring = {
    backgroundColor:
      chrt_id === 'c_a2_m2_s2_c1'
        ? '#2c809c'
        : chrt_id === 'c_a2_m2_kyc_cov_s2_c2'
        ? 'tomato'
        : chrt_id === 'flm_c_a2_m2_s2_c1'
        ? '#2c809c'
        : chrt_id === 'flm_c_a2_m2_kyc_cov_s2_c2'
        ? 'tomato'
        : chrt_id === 'flm_summary_t1_s2_c1'
        ? '#2c809c'
        : chrt_id === 'flm_summary_t2_s2_c2'
        ? '#3B8AA6'
        : 'white',
    borderRadius: '25px',
    height: '150px',
    paddingRight: '44px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)'
    },
    cursor: 'pointer'
  };

  const TextColoring = {
    color:
      chrt_id === 'c_a2_m2_s2_c1'
        ? 'white'
        : chrt_id === 'c_a2_m2_kyc_cov_s2_c2'
        ? 'white'
        : chrt_id === 'flm_c_a2_m2_s2_c1'
        ? 'white'
        : chrt_id === 'flm_c_a2_m2_kyc_cov_s2_c2'
        ? 'white'
        : chrt_id === 'flm_summary_t1_s2_c1'
        ? 'white'
        : chrt_id === 'flm_summary_t2_s2_c2'
        ? 'white'
        : 'black'
  };

  const card = (
    <React.Fragment>
      <CardContent>
        {filteredArray?.[0]?.[chrt_id]?.['chartParams']
          ? filteredArray?.[0]?.[chrt_id]?.['chartParams']?.map((item, index) =>
              item?.chartParamsValue?.[0]?.prm_val.match(/\d/) ? (
                <Typography key={index} color={TextColoring} gutterBottom>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                    {item?.chartParamsValue?.[0]?.prm_val.split('/')[0]}
                  </span>
                  {item?.chartParamsValue?.[0]?.prm_val.split('/')[1] ? <>/</> : null}
                  <span style={{ fontWeight: 'bold' }}>
                    {item?.chartParamsValue?.[0]?.prm_val.split('/')[1]}
                  </span>
                </Typography>
              ) : (
                <Typography
                  key={index}
                  sx={{ fontSize: 14, fontWeight: 'bold' }}
                  color={TextColoring}
                  gutterBottom
                >
                  {item?.chartParamsValue?.[0]?.prm_val}
                </Typography>
              )
            )
          : null}
      </CardContent>
    </React.Fragment>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Box>
              <Card
                variant="outlined"
                sx={Tilescoloring}
                onClick={() => handleClickOpen(chart_data)}
              >
                {card}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Tiels.propTypes = {
  chartParams: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object
};
export default Tiels;
